<template>
  <div>
    <v-app-bar  v-if="$vuetify.breakpoint.mdAndDown"
                clipped-left
                app
                flat
                color="slider_main"> <!--      color="grey darken-4" dark-->
        <v-app-bar-nav-icon color="white" @click.stop="$store.dispatch('toggleSidebarMenu')"></v-app-bar-nav-icon>

        <v-spacer></v-spacer>

        <v-img
            alt="Logo"

            contain
            :src="require('@/assets/icons/ic-sdc.svg')"
            transition="scale-transition"
            width="45"
            height="39"
            min-height="34"
            :style="{'min-height': '34px'}"
        />

        <v-spacer></v-spacer>

      <v-avatar  size="33">
        <v-img :src="require('@/assets/img/avatar-main.png')" />
      </v-avatar>
<!--      <v-menu-->
<!--          :close-on-content-click="false">-->
<!--        <template #activator="{on}">-->
<!--          <v-avatar  v-on="on" size="33">-->
<!--            <v-img :src="require('@/assets/img/avatar-main.png')" />-->
<!--          </v-avatar>-->
<!--        </template>-->
<!--        <template #default>-->
<!--          <v-list>-->
<!--            <v-list-item>-->
<!--              <v-list-item-title>Details</v-list-item-title>-->
<!--            </v-list-item>-->
<!--            <v-list-item>-->
<!--              <a role="button" class="d-flex flex text&#45;&#45;primary" @click="$store.dispatch('logout')">Logout</a>-->
<!--            </v-list-item>-->
<!--          </v-list>-->
<!--        </template>-->
<!--      </v-menu>-->

    </v-app-bar>

    <v-app-bar app flat color="" v-else>
          <crumbs :key="$route.fullPath"></crumbs>

          <v-spacer></v-spacer>

          <v-btn fab color="white" class="mr-4" small>
            <v-img contain height="14" width="18" :src="require('@/assets/icons/ic-message.svg')" />
          </v-btn>

          <v-btn fab class="mr-4" color="white" small>
            <v-img contain height="18" width="18" :src="require('@/assets/icons/ic-alert.svg')" />
          </v-btn>

          <v-menu
              :close-on-content-click="false">
            <template #activator="{on}">
              <v-btn class="pa-0" width="180" v-on="on" rounded color="white" large >
                <v-avatar
                    class="mr-3"
                    style="margin-left: 5px"
                    size="33">
                  <v-img :src="require('@/assets/img/avatar-main.png')" />
                </v-avatar>
                <span style="text-transform: none; font-family: 'Nunito', sans-serif; font-style: normal;font-weight: bold;font-size: 14px;line-height: 19px;">Eli Zager</span>
                <v-spacer></v-spacer>
                <v-icon style="margin-right: 5px">
                  mdi-chevron-down
                </v-icon>
              </v-btn>
            </template>
            <template #default>
              <v-list>
                <v-list-item>
                  <v-list-item-title>Details</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <a role="button" class="d-flex flex text--primary" @click="$store.dispatch('logout')">Logout</a>
                </v-list-item>
              </v-list>
            </template>
          </v-menu>
    </v-app-bar>

  </div>
</template>

<script>
const Crumbs = ()=> import(/* webpackChunkName: "crumbs" */"@/components/ui/Crumbs");
export default {
  name: "TheHeader",
  components: {
    Crumbs
  },
  data: () => ({}),
  methods: {}
}
</script>
